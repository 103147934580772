import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { setPageTitle, setMetaData } from '@/stores/MetaStore';

const app = createApp(App);
app.use(router);

app.config.globalProperties.$setTitle = setPageTitle;
app.config.globalProperties.$setMeta = setMetaData;

app.mount('#app')
