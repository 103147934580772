<template>
  <div class="footer">
    <div class="mobileButtons">
      <div class="title">KAN JE AL NIET MEER <span>WACHTEN</span>?</div>
      <a class="mobileButton callToAction call" href="tel:+32479262462">BELLEN?</a>
      <a class="mobileButton callToAction mail" href="mailto:hallo@carteconfituur.be">MAILEN?</a>
      <a class="mobileButton callToAction maps" href="http://maps.google.com/?q=Carte Confituur Grafisch Ontwerpstudio">GOOGLE MAPS'EN?</a>
    </div>
    <div class="footerWrapper">
      <div class="contactAndDocuments">
        <div class="contact">
          <div class="contactTitle">Contact</div>
          <div class="contactBlocks">
            <div class="contactBlock">
              <span class="name">Yasmine Tant</span>
              <span>Zuiderbiesten 5</span>
              <span>9810 Eke/Nazareth</span>
              <span>BTW (BE) 0780.943.337</span>
            </div>
            <div class="contactBlock">
              <span><a href="tel:+32479262462">+32 479 26 24 62</a></span>
              <span><a href="mailto:hallo@carteconfituur.be">hallo@carteconfituur.be</a></span>
              <span class="appointmentOnly">Enkel op afspraak</span>
            </div>
          </div>
        </div>
        
        <div class="documents">
          <div class="buttonbottom">
            <div class="socials">
              <a href="https://www.instagram.com/carteconfituur.be/" target="_blank"> <img class="logofooter" src="../assets/footer_instagram.png" alt="instagram" /></a>
              <a href="https://www.facebook.com/carteconfituur" target="_blank"> <img class="logofooter facebook" src="../assets/footer_facebook.png" alt="facebook" /> </a>
            </div>
          </div>
          <div class="document">
            <a href="/generalrules" target="_blank">Algemene voorwaarden</a>
          </div>
          <div class="document">
            <a href="/credits" target="_blank">Privacyverklaring & Credits</a>
          </div>
        </div>
      </div>
      <div class="mobileSocials">
        <a href="https://www.instagram.com/carteconfituur.be/" target="_blank"> <img class="logofooter" src="../assets/footer_instagram.png" alt="instagram" /></a>
        <a href="https://www.facebook.com/carteconfituur" target="_blank"> <img class="logofooter facebook" src="../assets/footer_facebook.png" alt="facebook" /> </a>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Footer',
});
</script>

<style lang="scss" scoped>
  .footer {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: center;

    .mobileButtons {
      display: none;
    }
    
    .footerWrapper {
      margin-left: Calc(15% + 35px);
      margin-right: Calc(15% + 35px);
      max-width: 1500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #E37B5A;
      padding-bottom: 50px;
      
      .title {
        font-family: "Montserrat Medium", sans-serif;
        font-weight: bold;
        color: #B4562D;
        letter-spacing: 1pt;
        margin-bottom: 15px;

        .subtitle {
          font-family: "Ramaraja", sans-serif;
          font-size: 29px;
        }
      }

      a {
        text-decoration: none;
        color: #333C40;
      }

      .buttonbottom {
        .socials {
          &:hover {
            cursor: pointer;
          }
          a img{
            width: 30pt;
            padding: 5px 0;
            
            &.facebook {
              padding: 5px;
            }
          }
        }
      }

      .mobileSocials {
        display: none;
      }
      
      .contactAndDocuments {
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: space-between;

        
        
        .contact {
          line-height: 1.5;
          .contactTitle {
            padding: 25px 0 25px 0;
            color: #E37B5A;
            font-weight: bold;
            font-size: large;
          }
          .contactBlocks {
            display: flex;
            align-items: end;

            .contactBlock {
              display: flex;
              flex-direction: column;
              padding: 0 50px 0 0;
              span {
                color: #333C40;
              }
              .name {
                color: #E37B5A;
              }
              
              .appointmentOnly {
                color: #E37B5A;
              }
            }

          }
        }

        .documents {
          display: flex;
          font-size: 10pt;
          flex-direction: column;
          justify-content: end;

          .document {
          }
        }
      }
    }  
  }
  @media only screen and (max-width: 845px) {
    .footer {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      
      margin-left: 10%;
      margin-right: 10%;
      
      width: initial;

      .mobileButtons {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 75px;
        
        .mobileButton {
          margin: 10px 0;
          
          &.call {
            color: #F4AF24;
          }
          
          &.mail {
            color: #E37B5A;
          }
          
          &.maps {
            color: #B4562D;
          }
        }
        
        .title {
          font-family: "Montserrat Medium", sans-serif;
          font-weight: bold;
          color: #B4562D;
          letter-spacing: 1pt;
          padding-bottom: 25px;
          font-size: 15px;

          span {
            font-family: "Ramaraja", sans-serif;
            font-size: 18px !important;
          }
        }

        .callToAction{
          font-family: "Montserrat Medium", sans-serif;
          border: 1px solid;
          font-size: 12px !important;
          text-decoration: none;
          padding: 15px 25px;
          width: 150px;

          &:hover {
            background-color: #E37B5A;
            color: white;
          }
        }
      }
      
      .footerWrapper {
        margin-left: initial;
        margin-right: initial;
        flex-direction: row;
        align-items: end;
        
        .mobileSocials {
          display: flex;
          flex-direction: column;

          a img{
            width: 30pt;
            padding: 2px;

            &.facebook {
              padding: 2px;
            }
          }
        }
        
        .contactAndDocuments {
          flex-direction: column;
          align-items: start;
          font-size: 12px !important;
          .contact {
            .contactTitle {
              font-size: 14px !important;
            }
            
            .contactBlocks {
              flex-direction: column;
              align-items: start;
              
              .contactBlock {
                padding: 0 0 20px 0;
                
              }
            }
          }
          
          .documents {
            .buttonbottom {
              .socials {
                display: none;
              }
            }
            
            .document {
              text-decoration: underline;
              padding-bottom: 7px;
              
              a {
                font-size: 12px !important;
              }
            }
            
          }
        }
        
        
      }
      
      
    }
  }
</style>