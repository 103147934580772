import {Image} from "@/models/Image";
import {Url} from "@/models/Url";
import {Credit} from "@/models/Credit";

export class ProjectItem {
    id: number;
    name: string;
    category: string;
    story: string[];
    title: string;
    subtitle: string;
    thumbnail: Image;
    images: Image[];
    credits: Credit[];
    customer: string;
    byCarte: string;
    technique: string;
    drawing: string;
    
    constructor(id: number, name: string, story: string[], thumbnail: Image, images: Image[], title: string, 
                subtitle: string, category: string, credits: Credit[], customer: string, byCarte: string,
                technique: string, drawing: string) {
        this.id = id;
        this.name = name;
        this.story = story;
        this.thumbnail = thumbnail;
        this.images = images;
        this.title = title;
        this.subtitle = subtitle;
        this.category = category;
        this.credits = credits;
        this.customer = customer;
        this.byCarte = byCarte;
        this.technique = technique;
        this.drawing = drawing;
    }
}