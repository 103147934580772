

import {defineComponent} from "vue";
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import {metaData, pageTitle} from "@/stores/MetaStore";

export default defineComponent({
  setup(){
    return { pageTitle, metaData };
  },
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  methods: {
    closeHamburger(){
      (this.$refs.navigation as any).closeHamburger();
    }
  }
});

