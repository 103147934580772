
import { defineComponent } from 'vue';
import router from "@/router";
import ProjectItemStore from "@/stores/ProjectItemStore";

export default defineComponent({
  name: 'Navigation',
  props: {
  },
  data() {
    return {
      checked: false
    }
  },
  // mounted(){
  // },
  methods: {
    goHome(){
      ProjectItemStore.activeProjectItem = undefined;
      router.push({name:'Home'});
    },
    closeHamburger(){
      this.checked = false;
    }
  }
});
